import React, { useState, useEffect } from 'react';
import './App.css';
import logo from './images/logo.png';
import favicon from "./images/favicon.png"

function App() {
    const [queueStatus, setQueueStatus] = useState('Wird geladen...');
    const [isProcessing, setIsProcessing] = useState(false); 
    const [file, setFile] = useState(null);
    const [diarization, setDiarization] = useState(false);
    const [email, setEmail] = useState('');
    const [timestamp, setTimestamp] = useState(false);
    const [uploadStatus, setUploadStatus] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [outputType, setOutputType] = useState('pdf');
    const [attachmentName, setAttachmentName] = useState(''); 
    const [attachmentNameError, setAttachmentNameError] = useState(''); 

    const language = "default";

    useEffect(() => {
        let timer;
        if (showErrorMessage) {
            timer = setTimeout(() => {
                setShowErrorMessage(false); 
            }, 5000);
        }

        // Cleanup-Funktion
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [showErrorMessage]);

    useEffect(() => {

        document.title = "LFO Services";

        const changeFavicon = () => {
            const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = favicon; 
            document.getElementsByTagName('head')[0].appendChild(link);
        };

        changeFavicon();

        const fetchQueueStatus = () => {
            fetch('https://service.lfo.tu-dortmund.de/api/v1/status')
                .then(response => response.json())
                .then(data => {
                    setQueueStatus(data["Queue Size"]);
                    setIsProcessing(data["Processing Job"]); 
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen des Status:', error);
                    setQueueStatus('Fehler');
                });
        };

        fetchQueueStatus();
        const intervalId = setInterval(fetchQueueStatus, 5000);
        return () => clearInterval(intervalId);
    }, []);

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleAttachmentNameChange = (event) => {
        const value = event.target.value;
        const regex = /^[a-zA-Z]+$/;

        if (regex.test(value) || value === '') {
            setAttachmentName(value);
            setAttachmentNameError('');
        } else {
            setAttachmentNameError('Der Dateiname darf nur Buchstaben des Alphabets enthalten.');
        }
    };

    const handleUpload = (event) => {
        event.preventDefault();

        if (!file) {
            setUploadStatus('Fehler: Bitte waehlen Sie eine Datei aus.');
            setShowErrorMessage(true);
            return;
        }
        if (!language) {
            setUploadStatus('Fehler: Bitte geben Sie eine Sprache an.');
            setShowErrorMessage(true);
            return;
        }
        if (!email) {
            setUploadStatus('Fehler: Bitte geben Sie eine E-Mail-Adresse an.');
            setShowErrorMessage(true);
            return;
        }
        if (!attachmentName) {
            setUploadStatus('Fehler: Bitte geben Sie einen Dateinamen an.');
            setShowErrorMessage(true);
            return;
        }
        if (attachmentNameError) {
            setUploadStatus('Fehler: Der Dateiname enthält ungültige Zeichen.');
            setShowErrorMessage(true);
            return;
        }

        setIsUploading(true); // Beginne mit dem Upload

        const formData = new FormData();
        formData.append('output_type', outputType);
        formData.append('file', file);
        formData.append('diarization', diarization);
        formData.append('email', email);
        formData.append('timestamp', timestamp);
        formData.append('attachment_name', attachmentName); 

        fetch('https://service.lfo.tu-dortmund.de/api/v1/upload/', {
            method: 'POST',
            body: formData,
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Netzwerkantwort war nicht ok');
                }
                return response.json();
            })
            .then(data => {
                setUploadStatus('Datei erfolgreich hochgeladen!');
                setShowErrorMessage(true);
                setIsUploading(false); // Upload abgeschlossen
                window.location.reload();
            })
            .catch(error => {
                console.error('Fehler beim Hochladen:', error);
                setUploadStatus('Fehler beim Hochladen der Datei.');
                setShowErrorMessage(true);
                setIsUploading(false); // Upload fehlgeschlagen
            });
    };

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="TU Dortmund Logo" id="logo"/>
            </header>
            <main>
                <div id="status-area">
                    <h1>Transkriptionsservice</h1>
                    <p>Die Interviews werden auf den LFO-Servern transkribiert, die Ergebnisse per E-Mail
                        zugeschickt und danach vom Server entfernt. Viele verschiedene Audio oder Videoformate werden akzeptiert. Die Dateien werden zudem lokal auf Viren getestet.</p>
                    <p align="left"><b>Dauer</b>: Wenige Minuten</p>
                    <p align="left"><b>Datenschutz</b>: Es werden keine Daten an Dritte bertragen und die Daten nur lokal verarbeitet.</p>
                </div>

                <div id="upload-area">
                    <input type="file" accept="video/*, audio/*" onChange={handleFileChange}/>
                    <input type="text" placeholder="Dateiname" value={attachmentName} onChange={handleAttachmentNameChange}/>
                    {attachmentNameError && <p className="error">{attachmentNameError}</p>}
                    <input type="email" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)}/>
                    <div className="checkbox-container">
                        <label className="checkbox-label" htmlFor="diarization-checkbox">Sprecher:</label>
                        <input
                            id="diarization-checkbox"
                            className="checkbox-custom"
                            type="checkbox"
                            checked={diarization}
                            onChange={(e) => setDiarization(e.target.checked)}
                        />
                    </div>
                    <div className="checkbox-container">
                        <label htmlFor="timestamp-checkbox">Zeitstempel:</label>
                        <input
                            id="timestamp-checkbox"
                            className="checkbox-custom"
                            type="checkbox"
                            checked={timestamp}
                            onChange={(e) => setTimestamp(e.target.checked)}
                        />
                    </div>
                    <div className="select-container">
                        <label htmlFor="output-type-select">Ausgabeformat:</label>
                        <select
                            id="output-type-select"
                            value={outputType}
                            onChange={(e) => setOutputType(e.target.value)}
                        >
                            <option value="pdf">PDF</option>
                            <option value="docx">Word</option>
                        </select>
                    </div>
                    <button id="upload-button" onClick={handleUpload}>Hochladen</button>
                </div>
                <div id="status-area">
                    <p>Interviews in der Warteschlange: <span id="queue-status">{queueStatus}</span></p>
                </div>
                <div id="processing-area">
                    <p>Status: <span id="processing-status">{isProcessing ? 'Transkribiert...' : 'Keine Aufgaben'}</span></p>
                </div>
                <div id="upload-status">
                    {showErrorMessage &&
                        <p className={`upload-status ${uploadStatus.includes('Fehler') ? 'error' : 'success'}`}>{uploadStatus}</p>}
                </div>
                {isUploading ? (
                    <div className="loading-container">
                        <div className="loading-spinner"></div>
                        <p>Upload...</p>
                    </div>
                ) : (
                    <div></div>
                )}
            </main>
            <footer>
                <p>Ansprechpartner: <a href="mailto:patrick.stuckmann@tu-dortmund.de"> Patrick Stuckmann</a> </p>
            </footer>
        </div>
    );
}

export default App;
